
import React from "react";
import "./HeaderComponent.scss"; 
const HeaderComponent = () => {
  return (
    <div className="header-component-wrapper">
      <h2>Home</h2> 
    </div>
  );
};

export default HeaderComponent;
