import React from "react";
import "./SideBarComponent.scss";
import authStorage from "../../auth"; 
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThLarge,
  faSignOutAlt,
  faUser, 
  faHeadset, 
  faBook, 
  faBoxes
} from "@fortawesome/free-solid-svg-icons"; 
import MenuListComponent from "../../components/MenuListComponent/MenuListComponent.jsx";
import { Link } from "react-router-dom";

const SideBarComponent = () => {
  const userdata = authStorage.getUser();

  const menuList = [
    {
      title: "Dashboard",
      icon: faThLarge,
      link: "/dashboard",
      role: ["admin"],
      isMobile: true,
    },{
      title: "Learning Materials",
      icon: faBook,
      link: "/ActionCards",
      role: ["admin"],
      isMobile: true,
    },{
      title: "Learning Activity",
      icon: faHeadset,
      link: "/Learning",
      role: ["admin"],
      isMobile: true,
    },{
      title: "Resources",
      icon: faBoxes,
      link: "/Resources",
      role: ["admin"],
      isMobile: true,
    }
  ];
  const tamilmenuList = [
    {
      title: "Dashboard",
      icon: faThLarge,
      link: "/dashboardtamil",
      role: ["admin"],
      isMobile: true,
    },{
      title: "Learning Materials",
      icon: faBook,
      link: "/ActionCardsTamil",
      role: ["admin"],
      isMobile: true,
    },{
      title: "Learning Activity",
      icon: faHeadset,
      link: "/LearningTamil",
      role: ["admin"],
      isMobile: true,
    },{
      title: "Resources",
      icon: faBoxes,
      link: "/ResourcesTamil",
      role: ["admin"],
      isMobile: true,
    }
  ];
  return (
    <div className="sidebar-component-wrapper">
      <div className="sidePane-content">
        <p className="sidebar-logo">SBA</p>
        <div className="username-wrapper">
          <div >
            <div
              className="rounded-circle border d-flex justify-content-center align-items-center Avatar-icon"
              alt="Avatar"
            >
              <FontAwesomeIcon icon={faUser} />
            </div>
          </div>
          <span className="username-sec">
            <span>{userdata.user.name}</span>
            <span>{userdata.user.email}</span>
          </span>
        </div>
        <hr className="side-hr" /> 

        <div className="menuList">
          {menuList.map((data, index) => (
            <NavLink
              exact="true"
              activeclassname="active"
              key={index}
              to={data.link}
            >
              <MenuListComponent title={data.title} icon={data.icon} />
            </NavLink>
          ))}
        </div>
        <p className="menusubtitle">Tamil</p>
        <div className="menuList">
          {tamilmenuList.map((data, index) => (
            <NavLink
              exact="true"
              activeclassname="active"
              key={index}
              to={data.link}
            >
              <MenuListComponent title={data.title} icon={data.icon} />
            </NavLink>
          ))}
        </div>
      </div>
      <div className="logout-wrapper">
        <hr className="side-hr" /> 
        <div className="logout-sec">
          <FontAwesomeIcon className="logout-icon" icon={faSignOutAlt} />
          <Link to={"/"}>Logout</Link>
        </div>
      </div>
    </div>
  );
};

export default SideBarComponent;
