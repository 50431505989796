import React, { useState } from "react";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import CONSTANTS from "../../constants";
import authStorage from "../../auth";
import PropTypes from "prop-types";
import "./FileUpload.scss";

const ImageUpload = (props) => {
  const [image, setImage] = useState();
  const [fileInput, setfileInput] = useState(false);
  const [imageName, setImageName] = useState("");
  const [error, setError] = useState("");

  const [imageUploadButtonName, setImageUploadButtonName] =
    useState("Upload image");

  const handleImage = (event) => {
    let fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    setImageUploadButtonName(event.target.files[0].name);
    setImageName(event.target.files[0].name);
    if (fileInput) {
      setfileInput(fileInput);
      Resizer.imageFileResizer(
        event.target.files[0],
        300,
        300,
        "PNG",
        100,
        0,
        (uri) => {
          setImage(uri);
        },
        "base64"
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${CONSTANTS.devuri}imageupload`,
        { image, id: imageUploadButtonName },
        {
          headers: { "x-auth-token": authStorage.getToken() },
        }
      );
      setImageUploadButtonName("Upload image");
      props.imageupload(response.data.data, imageName);
    } catch (error) {
      setError("Something Went wrong try with other Image");
    }
  };
  return (
    <div >
      <div className="fileupload-comp-sec">
        <input
          className="form-control"
          onChange={handleImage}
          type="file"
          id="formFile"
        />
        <button
          disabled={!fileInput}
          onClick={handleSubmit}
          className="upload-btn"
        >
          Upload
        </button>
      </div>

      <p className="errormsg">{error}</p>
    </div>
  );
};
ImageUpload.propTypes = {
  imageupload: PropTypes.func,
};

export default ImageUpload;
