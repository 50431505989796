import React from "react";
import "./App.scss";
import ProtectedRoute from "./ProtectedRoute.js";
import Login from "./components/Login/Login.jsx";
import DashboardComponent from "./components/DashboardComponent/DashboardComponent.jsx";
import ActionCardsComponent from "./components/ActionCardsComponent/ActionCardsComponent.jsx";
import LearningComponent from "./components/LearningComponent/LearningComponent.jsx";
import Resources from "./components/Resources/Resources.jsx";
import { HashRouter, Route, Routes } from "react-router-dom";
import DashboardTamilComponent from "./components/DashboardComponent/DashboardTamilComponent.jsx";
import ActionCardsTamilComponent from "./components/ActionCardsComponent/ActionCardsTamilComponent.jsx";
import LearningTamilComponent from "./components/LearningComponent/LearningTamilComponent.jsx";
import ResourcesTamil from "./components/Resources/ResourcesTamil.jsx";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import SupportPage from "./components/SupportPage/SupportPage";
const App = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" exact element={<Login />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/support" element={<SupportPage />} />

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <DashboardComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboardtamil"
          element={
            <ProtectedRoute>
              <DashboardTamilComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ActionCards"
          element={
            <ProtectedRoute>
              <ActionCardsComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ActionCardsTamil"
          element={
            <ProtectedRoute>
              <ActionCardsTamilComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Learning"
          element={
            <ProtectedRoute>
              <LearningComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/LearningTamil"
          element={
            <ProtectedRoute>
              <LearningTamilComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ResourcesTamil"
          element={
            <ProtectedRoute>
              <ResourcesTamil />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Resources"
          element={
            <ProtectedRoute>
              <Resources />
            </ProtectedRoute>
          }
        />
      </Routes>
    </HashRouter>
  );
};
export default App;
