import jwtDecode from "jwt-decode";
const key = "authToken";

const storeToken = (authToken) => {
  try {
    localStorage.setItem(key, authToken);
    localStorage.setItem("isAuthenticated", true);
  } catch (error) {
    console.log("Error storing the auth token", error);
  }
};
const storeUser = (role) => {
  try {
    localStorage.setItem("role", role);
  } catch (error) {
    console.log("Error storing the auth token", error);
  }
};
const setIsAuth = () => {
  try {
    localStorage.setItem("isAuth", true);
  } catch (error) {
    console.log("Error storing the auth token", error);
  }
};
const getIsAuth = () => {
  try {
    return localStorage.getItem("isAuth");
  } catch (error) {
    console.log("Error storing the auth token", error);
  }
};
const getToken = () => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    console.log("Error getting the auth token", error);
  }
};

const getUser = () => {
  const token = getToken();
  return token ? jwtDecode(token) : null;
};

const removeToken = () => {
  try {
    localStorage.clear();
  } catch (error) {
    console.log("Error removing the auth token", error);
  }
};

export default { getToken, getUser, removeToken, storeToken, storeUser ,setIsAuth, getIsAuth};
