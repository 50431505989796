import React, { useState, useEffect } from "react";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import CONSTANTS from "../../constants";
import axios from "axios";
import authStorage from "../../auth";
import QuestionComponent from "../QuestionComponent/QuestionComponent.jsx";
import QuestionSequenceComponent from "../QuestionComponent/QuestionSequenceComponent.jsx";
import TrueorFalseQuestionComponent from "../QuestionComponent/TrueorFalseQuestionComponent.jsx";
import QuestionMatchingComponent from "../QuestionComponent/QuestionMatchingComponent.jsx";
import QuestionImageComponent from "../QuestionComponent/QuestionImageComponent.jsx";
import "./LearningComponent.scss";
import NoDataFound from "../../globalComponents/NoDataFound/NoDataFound.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTrashAlt,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
const LearningComponent = () => {
  const [catList, setCatList] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [errormsg, setErrorMsg] = useState();
  const [show, setShow] = useState(false);
  const [torFshow, setTorFShow] = useState(false);
  const [seqshow, setSeqShow] = useState(false);
  const [matchshow, setMatchShow] = useState(false);
  const [imageshow, setImageShow] = useState(false);
  const [catID, setcatID] = useState();
  const [iscatID, setIsCatID] = useState(false);
  const [type, setType] = useState();
  const [editData, setEditData] = useState({});
  const [editSeqData, setEditSeqData] = useState({});
  const [iseditData, setisEditData] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSeqClose = () => setSeqShow(false);
  const handleSeqShow = () => setSeqShow(true);
  const handleTorFClose = () => setTorFShow(false);
  const handleTorFShow = () => setTorFShow(true);

  const handleMatchClose = () => setMatchShow(false);
  const handleMatchShow = () => setMatchShow(true);

  const handleImageClose = () => setImageShow(false);
  const handleImageShow = () => setImageShow(true);
  useEffect(() => {
    getcatList();
  }, []);
  const getcatList = () => {
    axios
      .get(CONSTANTS.devuri + "tamil/homelist", {
        headers: { "x-auth-token": authStorage.getToken() },
      })
      .then((response) => {
        setCatList(response.data.imagelist);
      })
      .catch((err) => {
        setErrorMsg(err.response.data.errors[0].msg);
        console.log(errormsg);
      });
  };
  const getQuestionList = (qtype) => {
    axios
      .get(CONSTANTS.devuri + `tamil/questionlist/${catID}/${qtype}`, {
        headers: { "x-auth-token": authStorage.getToken() },
      })
      .then((response) => {
        setQuestionList(response.data.imagelist);
      })
      .catch((err) => {
        setErrorMsg(err.response.data.errors[0].msg);
        console.log(errormsg);
      });
  };
  const categoryChange = (e) => {
    setcatID(e.target.value);
    setType("");
    setIsCatID(false);
  };
  const categoryTypeChange = (e) => {
    setType(e.target.value);
    setIsCatID(true);
    getQuestionList(e.target.value);
  };

  const handleAdd = () => {
    setEditData({});
    setisEditData(false);
    setTimeout(() => {
      handleShow();
    }, 100);
  };

  const handleTorFAdd = () => {
    setEditData({});
    setisEditData(false);
    setTimeout(() => {
      handleTorFShow();
    }, 100);
  };

  const handleImageAdd = () => {
    setEditData({});
    setisEditData(false);
    setTimeout(() => {
      handleImageShow();
    }, 100);
  };

  const handleSeqAdd = () => {
    setEditSeqData({});
    setisEditData(false);
    setTimeout(() => {
      handleSeqShow();
    }, 100);
  };
  const handleMatchAdd = () => {
    setEditSeqData({});
    setisEditData(false);
    setTimeout(() => {
      handleMatchShow();
    }, 100);
  };
  const handleImageEdit = (data) => {
    setEditData(data);
    setisEditData(true);
    setTimeout(() => {
      handleImageShow();
    }, 100);
  };
  const handleEdit = (data) => {
    setEditData(data);
    setisEditData(true);
    if (data.questiontype === "trueorfalse") {
      setTimeout(() => {
        handleTorFShow();
      }, 100);
    } else {
      setTimeout(() => {
        handleShow();
      }, 100);
    }
  };
  const handleSeqEdit = (data) => {
    setEditSeqData(data);
    setisEditData(true);
    if (data.questiontype === "sequence") {
      setTimeout(() => {
        handleSeqShow();
      }, 100);
    } else {
      setTimeout(() => {
        handleMatchShow();
      }, 100);
    }
  };

  const deleteList = (id) => {
    axios
      .delete(CONSTANTS.devuri + `tamil/questionlist/${id}`, {
        headers: { "x-auth-token": authStorage.getToken() },
      })
      .then(() => {
        getQuestionList(type);
      })
      .catch(() => {});
  };
  return (
    <div className="inner-content-wrapper question-container">
      <div className="question-cat-selection">
        <Form.Select aria-label="Category Change" onChange={categoryChange}>
          <option>Select Category</option>
          {catList.map((data) => (
            <option key={data._id} value={data._id}>
              {data.title}
            </option>
          ))}
        </Form.Select>

        <Form.Select value={type} onChange={categoryTypeChange}>
          <option>Select Type</option>
          <option value={"beginner"}>Beginner</option>
          <option value={"intermediate"}>Intermediate</option>
          <option value={"proficient"}>Proficient</option>
        </Form.Select>
      </div>
      {iscatID ? (
        <>
          <div className="title-section">
            <p>Question List</p>
            <div className="title-sec-btn">
              <Button variant="primary" onClick={handleAdd}>
                Add New Question
              </Button>
              <Button variant="primary" onClick={handleSeqAdd}>
                Add Sequence Question
              </Button>
              <Button variant="primary" onClick={handleMatchAdd}>
                Match The Following Question
              </Button>
              <Button variant="primary" onClick={handleTorFAdd}>
                Add True Or False Question
              </Button>
              <Button variant="primary" onClick={handleImageAdd}>
                Add Image Question
              </Button>
            </div>
          </div>
          {questionList.length > 0 ? (
            questionList.map((data, index) => (
              <div key={index} className="question-wrapper">
                <div className="question-title">
                  <div>
                    <p>{data.question}</p>
                    {(data.questiontype === "image" && data.isQuestionImageUploaded) ? (
                      <img src={data.questionImage} className="list-image" />
                    ) : null}
                  </div>
                  <div>
                    {data.questiontype === "sequence" || data.questiontype === "match" ? (
                      <FontAwesomeIcon
                        className="edit-icon"
                        icon={faEdit}
                        onClick={() => handleSeqEdit(data)}
                      />
                    ) : data.questiontype === "trueorfalse" || data.questiontype === "multiple" ? (
                      <FontAwesomeIcon
                        className="edit-icon"
                        icon={faEdit}
                        onClick={() => handleEdit(data)}
                      />
                    ) : null}
                    {data.questiontype === "image" ? (
                      <FontAwesomeIcon
                        className="edit-icon"
                        icon={faEdit}
                        onClick={() => handleImageEdit(data)}
                      />
                    ) : null}
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      onClick={() => deleteList(data._id)}
                    />
                  </div>
                </div>
                {data.questiontype === "match" ? (
                  <Row>
                    <Col>
                      <p>Options </p>
                      {data.optionSqu.map((innerdata, innerIndex) => (
                        <li key={innerIndex}>{innerdata.value}</li>
                      ))}
                    </Col>
                    <Col>
                      <p>Correct Sequence</p>
                      {data.optionSquAnswer.map((innerdata, innerIndex) => (
                        <li key={innerIndex}>{innerdata.value}</li>
                      ))}
                    </Col>
                    <Col>
                      <p>Match options</p>
                      {data.optionSquAnswer.map((innerdata, innerIndex) => (
                        <li key={innerIndex}>{innerdata.match}</li>
                      ))}
                    </Col>
                  </Row>
                ) : null}

                {data.questiontype === "image" ? (
                  <ol type="A">
                    {data.optionArry.map((innerdata, innerIndex) => (
                      <li key={innerIndex}>
                        {innerdata.value}
                        {innerdata.isImageUploaded ? (
                          <img src={innerdata.image} className="list-image" />
                        ) : null}
                        {innerdata.checkbox ? (
                          <FontAwesomeIcon
                            className="check-icon"
                            icon={faCheckCircle}
                          />
                        ) : null}
                      </li>
                    ))}
                  </ol>
                ) : null}
                {data.questiontype === "sequence" ? (
                  <Row>
                    <Col>
                      <p>Options </p>
                      {data.optionSqu.map((innerdata, innerIndex) => (
                        <li key={innerIndex}>{innerdata.value}</li>
                      ))}
                    </Col>
                    <Col>
                      <p>Correct Sequence</p>
                      {data.optionSquAnswer.map((innerdata, innerIndex) => (
                        <li key={innerIndex}>{innerdata.value}</li>
                      ))}
                    </Col>
                  </Row>
                ) : data.questiontype === "trueorfalse" || data.questiontype === "multiple" ? (
                  <>
                    <ol type="A">
                      {data.optionArry.map((innerdata, innerIndex) => (
                        <li key={innerIndex}>
                          {innerdata.value}
                          {innerdata.checkbox ? (
                            <FontAwesomeIcon
                              className="check-icon"
                              icon={faCheckCircle}
                            />
                          ) : null}
                        </li>
                      ))}
                    </ol>
                    {data.questiontype === "trueorfalse" ? (
                      <p>Correct Answer: {data.truefalseAnswer}</p>
                    ) : null}
                  </>
                ) : null}
              </div>
            ))
          ) : (
            <NoDataFound text={"No Data Found"} />
          )}
        </>
      ) : null}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-60w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QuestionComponent
            catID={catID}
            type={type}
            editData={editData}
            isEdit={iseditData}
            getQuestionList={(type) => getQuestionList(type)}
            handleClose={handleClose}
            lang={'tamil'}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={seqshow}
        onHide={handleSeqClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-60w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QuestionSequenceComponent
            catID={catID}
            type={type}
            editData={editSeqData}
            isEdit={iseditData}
            getQuestionList={(type) => getQuestionList(type)}
            handleClose={handleSeqClose}
            lang={'tamil'}

          />
        </Modal.Body>
      </Modal>

      <Modal
        show={torFshow}
        onHide={handleTorFClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-60w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TrueorFalseQuestionComponent
            catID={catID}
            type={type}
            editData={editData}
            isEdit={iseditData}
            getQuestionList={(type) => getQuestionList(type)}
            handleClose={handleTorFClose}
            lang={'tamil'}

          />
        </Modal.Body>
      </Modal>
      <Modal
        show={matchshow}
        onHide={handleMatchClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-60w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QuestionMatchingComponent
            catID={catID}
            type={type}
            editData={editSeqData}
            isEdit={iseditData}
            getQuestionList={(type) => getQuestionList(type)}
            handleClose={handleMatchClose}
            lang={'tamil'}

          />
        </Modal.Body>
      </Modal>

      <Modal
        show={imageshow}
        onHide={handleImageClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-60w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Image Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QuestionImageComponent
            catID={catID}
            type={type}
            editData={editData}
            isEdit={iseditData}
            getQuestionList={(type) => getQuestionList(type)}
            handleClose={handleImageClose}
            lang={'tamil'}

          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LearningComponent;
