import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import authStorage from "./auth";
import HeaderComponent from "./components/HeaderComponent/HeaderComponent.jsx";
import SideBarComponent from "./components/SideBarComponent/SideBarComponent.jsx";

const ProtectedRoute = ({ children }) => {
  const auth = authStorage.getIsAuth();
  return auth ? (
    <>
      <div className="content-wrapper">
        <SideBarComponent />
        <div className="main-section">
          <HeaderComponent />
          <div className="comp-wrapper">{children}</div>
        </div>
      </div>
    </>
  ) : (
    <Navigate to="/" />
  );
};
ProtectedRoute.propTypes = {
  children: PropTypes.node,
};
export default ProtectedRoute;
