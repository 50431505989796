import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import "./Resources.scss";
import CONSTANTS from "../../constants";
import axios from "axios";
import authStorage from "../../auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import NoDataFound from "../../globalComponents/NoDataFound/NoDataFound.jsx";

const Resources = () => {
  const [catList, setCatList] = useState([]);
  const [show, setShow] = useState(false);
  const [newresource, setNewResource] = useState(false);
  const [desc, setDesc] = useState();
  const [mode, setMode] = useState("add");
  const [errormsg, setErrorMsg] = useState();
  const [catID, setCatID] = useState();
  const [IsCatID, setIsCatID] = useState(false);
  const [productDetails, setProductDetails] = useState([]);

  const [link, setLink] = useState({
    value: "",
    errormsg: "",
    showError: false,
  });
  const handleChange = (e) => {
    setLink({
      value: e.target.value,
      errormsg: "",
      showError: false,
    });
  };

  const handleAdd = () => {
    setMode("add");
    setLink({
      value: "",
      errormsg: "",
      showError: false,
    });
    setTimeout(() => {
      handleShow();
    }, 100);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    getcatList();
  }, []);
  const getcatList = () => {
    axios
      .get(CONSTANTS.devuri + "homelist", {
        headers: { "x-auth-token": authStorage.getToken() },
      })
      .then((response) => {
        setCatList(response.data.imagelist);
      })
      .catch(() => {});
  };
  const categoryChange = (e) => {
    setCatID(e.target.value);
    getDetails(e.target.value);
    setIsCatID(true);
  };

  const deleteList = (id) => {
    axios
      .delete(CONSTANTS.devuri + `resources/${id}`, {
        headers: { "x-auth-token": authStorage.getToken() },
      })
      .then(() => {
        getDetails(catID);
      })
      .catch(() => {});
  };
  const handleEdit = (data) => {
    setMode("edit");
    setLink({
      value: data.link,
      errormsg: "",
      showError: false,
    });
    setDesc(data.desc);
    setNewResource(data.newresource);
    setTimeout(() => {
      handleShow();
    }, 100);
  };
  const getDetails = (id) => {
    axios
      .get(CONSTANTS.devuri + `resources/${id}`, {
        headers: { "x-auth-token": authStorage.getToken() },
      })
      .then((response) => {
        setProductDetails(response.data.imagelist);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlesubmit = () => {
    if (!link.value) {
      setLink({
        value: link.value,
        errormsg: "This is required field",
        showError: true,
      });
    } else {
      const obj = {
        link: link.value,
        newresource: newresource,
        desc: desc,
        catID: catID,
      };
      axios
        .post(CONSTANTS.devuri + `resources/`, obj, {
          headers: { "x-auth-token": authStorage.getToken() },
        })
        .then(() => {
          handleClose();
          getDetails(catID);
        })
        .catch((err) => {
          setErrorMsg(err.response.data.errors[0].msg);
        });
    }
  };
  const handleUpdate = () => {
    if (!link.value) {
      setLink({
        value: link.value,
        errormsg: "This is required field",
        showError: true,
      });
    } else {
      const obj = {
        link: link.value,
        newresource: newresource,
        desc: desc,
        catID: catID,
      };
      axios
        .patch(CONSTANTS.devuri + `resources/${catID}`, obj, {
          headers: { "x-auth-token": authStorage.getToken() },
        })
        .then(() => {
          handleClose();
          getDetails(catID);
        })
        .catch((err) => {
          setErrorMsg(err.response.data.errors[0].msg);
        });
    }
  };

  return (
    <div className="inner-content-wrapper">
      <div>
        <Form.Select
          aria-label="Default select example"
          onChange={categoryChange}
        >
          <option>Select Category</option>
          {catList.map((data) => (
            <option key={data._id} value={data._id}>
              {data.title}
            </option>
          ))}
        </Form.Select>
      </div>
      {IsCatID ? (
        <div className="header-title">
          <p>List</p>
          <Button variant="primary" onClick={handleAdd}>
            Add
          </Button>
        </div>
      ) : null}
      {productDetails.length > 0 ? (
        productDetails.map((data) => (
          <div key={data._id} className="resource-list-wrapper">
            <div className="header-list">
              <div>
                <a href={data.link}>{data.link}</a>
                {data.newresource ? (
                  <img src="assets/new-icon-gif-9.jpg" alt="" />
                ) : null}
              </div>
              <span>
                <FontAwesomeIcon
                  className="edit-icon"
                  icon={faEdit}
                  onClick={() => handleEdit(data)}
                />
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  onClick={() => deleteList(data._id)}
                />
              </span>
            </div>
            {data.newresource ? <p>{data.desc}</p> : null}
          </div>
        ))
      ) : (
        <NoDataFound text={"No Data Found"} />
      )}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Resource Link</Form.Label>
              <Form.Control
                type="text"
                name="link"
                value={link.value}
                onChange={handleChange}
                placeholder="link"
              />
              {link.showError ? (
                <p className="errMsg">{link.errormsg}</p>
              ) : null}
            </Form.Group>

            {errormsg && <p className="errMsg">{errormsg}</p>}

            <Form.Check
              type="checkbox"
              inline
              checked={newresource}
              label="New Update"
              onChange={(e) => setNewResource(e.target.checked)}
            />
            {newresource ? (
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="Description"
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                  placeholder="Description"
                />
              </Form.Group>
            ) : null}
            <div className="cta-btn">
              {mode === "add" ? (
                <Button
                  className="mr-3"
                  variant="primary"
                  onClick={handlesubmit}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  className="mr-3"
                  variant="primary"
                  onClick={handleUpdate}
                >
                  Update
                </Button>
              )}
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Resources;
