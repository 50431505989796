import React from "react";
import "./MenuListComponent.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from 'prop-types'

const MenuListComponent = (props) => {
  return (
    <div className="menulis-wrapper" >
      <FontAwesomeIcon className="icon" icon={props.icon} />
      <span>{props.title}</span>
    </div>
  );
};
MenuListComponent.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  key: PropTypes.string
}
export default MenuListComponent;
