import React from "react";
import PropTypes from "prop-types";
import "./NoDataFound.scss";

const NoDataFound = (props) => {
  return <div className="nodatafound">{props.text}</div>;
};
NoDataFound.propTypes = {
  text: PropTypes.string,
};
export default NoDataFound;
