
/* eslint-disable */
import React, { useState } from "react";
import "./ImageUploadComponent.scss";
import { Form } from "react-bootstrap";
import FileUpload from "../../globalComponents/FileUpload/FileUpload.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const ImageUploadComponent = () => {
    const [isImageUploaded, setIsImageUploaded] = useState(false);
    const [imagedata, setimagedata] = useState();
    const imageupload = (link, imageName) => {
        setimagedata({ image: link.path, alt: imageName });
        setTimeout(() => {
            setIsImageUploaded(true);
        }, 100);
    };
    return (
        <div className="image-upload-wrapper">
          { isImageUploaded ? (
              <div className='file-upload-sec'>
                  <img src={imagedata.image} />
                  <p>{imagedata.image}</p>
                  <FontAwesomeIcon
                      className="close-icon"
                      onClick={() => setIsImageUploaded(false)}
                      icon={faTimes}
                  />
              </div>
            ) : (
              <Form.Group className="mb-3">
                  <FileUpload imageupload={imageupload} />
              </Form.Group>
            )}
        </div>
    );
};

export default ImageUploadComponent;
