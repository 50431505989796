/* eslint-disable */
import React from "react";

import "./SupportPage.scss";
const SupportPage = () => {
  return (
    <div className="SupportPage-conatiner">
        
        <div className="SupportPage-Box">
            <h2>For Support Contact Us</h2>
            <img src="assets/auth.png" className="mb-3" alt="auth" /> 
            <p>Email : <a href="mailto:sbaapp2022@gmail.com">sbaapp2022@gmail.com</a></p>
        </div>
    </div>
  );
};

export default SupportPage;
