import React, { useState, useEffect } from "react";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import "./QuestionComponent.scss";
import PropTypes from "prop-types";
import CONSTANTS from "../../constants";
import axios from "axios";
import authStorage from "../../auth";
import FileUpload from "../../globalComponents/FileUpload/FileUpload.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
const QuestionImageComponent = (props) => {
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [imagedata, setimagedata] = useState();
  const [question, setQuestion] = useState({
    value: props.editData?.question || "",
    errormsg: "",
    showError: false,
  });
  const [errormsg, setErrorMsg] = useState();
  useEffect(() => {
    if (props.editData) setimagedata({ image: props.editData?.questionImage });
    setIsImageUploaded(props.editData?.isQuestionImageUploaded);
  }, [props.editData]);
  const [optionArry, setOptionArry] = useState(
    props.editData?.optionArry || [
      {
        value: "",
        errormsg: "",
        showError: false,
        checkbox: false,
        isImageUploaded: false,
        image: "",
      },
    ]
  );
  const handleChange = (e, index) => {
    const newData = [...optionArry];
    newData[index].value = e.target.value;
    newData[index].errormsg = "";
    newData[index].showError = false;
    setOptionArry(newData);
  };

  const dataimageupload = (data, index) => {
    const newData = [...optionArry];
    newData[index].isImageUploaded = true;
    newData[index].image = data.path;
    setOptionArry(newData);
  };

  const toggleItem = (e, index) => {
    const newData = [...optionArry];
    newData[index].checkbox = e.target.checked;
    setOptionArry(newData);
  };

  const handleChangeQuestion = (e) => {
    setQuestion({
      value: e.target.value,
      errormsg: "",
      showError: false,
    });
  };
  const handleAddOption = () => {
    const newData = [...optionArry];
    newData.push({
      value: "",
      errormsg: "",
      showError: false,
      checkbox: false,
      isImageUploaded: false,
      image: "",
    });
    setOptionArry(newData);
  };
  const handleRemoveOption = (index) => {
    const newData = [...optionArry];
    newData.splice(index, 1);
    setOptionArry(newData);
  };
  const imageupload = (link, imageName) => {
    setimagedata({ image: link.path, alt: imageName });
    setTimeout(() => {
      setIsImageUploaded(true);
    }, 100);
  };
  const addQuestion = (question, optionArry) => {
    const obj = {
      question: question.value,
      questionImage: imagedata.image,
      isQuestionImageUploaded: isImageUploaded,
      optionArry: optionArry,
      catID: props.catID,
      type: props.type,
      questiontype: "image",
    };
    axios
      .post(CONSTANTS.devuri + `${props.lang ==='tamil'? 'tamil/':''}questionlist`, obj, {
        headers: { "x-auth-token": authStorage.getToken() },
      })
      .then(() => {
        props.handleClose();
        props.getQuestionList(props.type);
      })
      .catch((err) => {
        setErrorMsg(err.response.data.errors[0].msg);
        console.log(errormsg);
      });
  };

  const updateQuestion = (question, optionArry) => {
    const obj = {
      question: question.value,
      questionImage: imagedata.image,
      isQuestionImageUploaded: isImageUploaded,
      optionArry: optionArry,
      catID: props.catID,
      type: props.type,
      questiontype: "image",
    };
    axios
      .patch(CONSTANTS.devuri + `${props.lang ==='tamil'? 'tamil/':''}questionlist/${props.editData._id}`, obj, {
        headers: { "x-auth-token": authStorage.getToken() },
      })
      .then(() => {
        props.handleClose();
        props.getQuestionList(props.type);
      })
      .catch((err) => {
        setErrorMsg(err.response.data.errors[0].msg);
        console.log(errormsg);
      });
  };
  return (
    <div className="">
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Question</Form.Label>
          <Form.Control
            type="text"
            name="question"
            value={question.value}
            onChange={handleChangeQuestion}
            placeholder="Question"
          />
          {isImageUploaded ? (
            <div className="qus-img-sec">
              <img className="popup-img" src={imagedata.image} />
              <FontAwesomeIcon
                className="close-icon"
                onClick={() => setIsImageUploaded(false)}
                icon={faTimes}
              />
            </div>
          ) : (
            <Form.Group className="mt-3 w-50">
              <FileUpload imageupload={imageupload} />
            </Form.Group>
          )}

          {question.showError ? (
            <p className="errMsg">{question.errormsg}</p>
          ) : null}
        </Form.Group>
        {optionArry.map((data, index) => (
          <Form.Group key={index} className="mb-3">
            <Container>
              <Row >
                <Col md={4}>
                  <Form.Control
                    type="text"
                    name="Option"
                    value={data.value}
                    placeholder="Option"
                    onChange={(e) => {
                      handleChange(e, index);
                    }}
                  />
                  {data.showError ? (
                    <p className="errMsg">{data.errormsg}</p>
                  ) : null}
                </Col>

                <Col md={1}>Or</Col>
                <Col md={5}>
                  {data.isImageUploaded ? (
                    <img className="popup-img" src={data.image} />
                  ) : (
                    <Form.Group className="">
                      <FileUpload
                        imageupload={(value) => dataimageupload(value, index)}
                      />
                    </Form.Group>
                  )}
                </Col> 
                <Col md={2}>
                  <Form.Check
                    type="checkbox"
                    inline
                    checked={data.checkbox}
                    onChange={(e) => {
                      toggleItem(e, index);
                    }}
                  />
                  <Button
                    variant="primary"
                    className="btn-remove-sec"
                    onClick={() => handleRemoveOption(index)}
                  >
                    Remove
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form.Group>
        ))}
        <Button variant="primary" onClick={handleAddOption}>
          Add More
        </Button>
      </Form>
      <div className="btn-sec">
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
        {props.isEdit ? (
          <Button
            variant="primary"
            onClick={() => updateQuestion(question, optionArry)}
          >
            Update
          </Button>
        ) : (
          <Button
            variant="primary"
            onClick={() => addQuestion(question, optionArry)}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );
};
QuestionImageComponent.propTypes = {
  handleClose: PropTypes.func,
  getQuestionList: PropTypes.func,
  catID: PropTypes.string,
  type: PropTypes.string,
  editData: PropTypes.object,
  isEdit: PropTypes.bool,
  lang: PropTypes.string
};
export default QuestionImageComponent;
