import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import "./DashboardComponent.scss";
import ImageUpload from "../../globalComponents/FileUpload/ImageUpload.jsx";
import CONSTANTS from "../../constants";
import axios from "axios";
import authStorage from "../../auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit,faTimes } from "@fortawesome/free-solid-svg-icons";
const DashboardComponent = () => {
  const [show, setShow] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [imagedata, setimagedata] = useState();
  const [errormsg, setErrorMsg] = useState();
  const [productList, setProductList] = useState([]);
  const [mode, setMode] = useState("add");
  const [editId, setEditId] = useState(); 

  const [title, setTitle] = useState({
    value: "",
    errormsg: "",
    showError: false,
  });
  useEffect(() => {
    getList();
  }, []);
  const getList = () => {
    axios
      .get(CONSTANTS.devuri + "homelist", {
        headers: { "x-auth-token": authStorage.getToken() },
      })
      .then((response) => {
        setProductList(response.data.imagelist);
      })
      .catch((err) => {
        setErrorMsg(err.response.data.errors[0].msg);
      });
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const imageupload = (link, imageName) => {
    setimagedata({ image: link.path, alt: imageName });
    setTimeout(() => {
      setIsImageUploaded(true);
    }, 100);
  };

  const handleChange = (e) => {
    setTitle({
      value: e.target.value,
      errormsg: "",
      showError: false,
    });
  };
  const deleteList = (id) => {
    axios
      .delete(CONSTANTS.devuri + `homelist/${id}`, {
        headers: { "x-auth-token": authStorage.getToken() },
      })
      .then(() => {
        getList();
      })
      .catch(() => { });
  };
  const handleEdit = (data) => {
    setMode("edit");
    setEditId(data._id);
    setTitle({
      value: data.title,
      errormsg: "",
      showError: false,
    });
    setIsImageUploaded(true);
    setimagedata({ image: data.image, alt: data.alt });
    setTimeout(() => {
      handleShow();
    }, 100);
  };
  const handleAdd = () => {
    setMode("add");
    setTitle({
      value: "",
      errormsg: "",
      showError: false,
    });
    setIsImageUploaded(false);
    setTimeout(() => {
      handleShow();
    }, 100);
  };

  const handlesubmit = () => {
    if (!title.value) {
      setTitle({
        value: title.value,
        errormsg: "This is required field",
        showError: true,
      });
    } else {
      const obj = {
        title: title.value,
        image: imagedata.image,
        alt: imagedata.alt,
      };
      axios
        .post(CONSTANTS.devuri + "homelist", obj, {
          headers: { "x-auth-token": authStorage.getToken() },
        })
        .then(() => {
          setShow(false);
          getList();
        })
        .catch((err) => {
          setErrorMsg(err.response.data.errors[0].msg);
        });
    }
  };
  const handleUpdate = () => {
    if (!title.value) {
      setTitle({
        value: title.value,
        errormsg: "This is required field",
        showError: true,
      });
    } else {
      const obj = {
        title: title.value,
        image: imagedata.image,
        alt: imagedata.alt,
      };
      axios
        .patch(CONSTANTS.devuri + `homelist/${editId}`, obj, {
          headers: { "x-auth-token": authStorage.getToken() },
        })
        .then(() => {
          setShow(false);
          getList();
        })
        .catch((err) => {
          setErrorMsg(err.response.data.errors[0].msg);
        });
    }
  };
  return (
    <div className="inner-content-wrapper">
      <div className="header-title">
        <p>List</p>
        <Button variant="primary" onClick={handleAdd}>
          Add
        </Button>
      </div>
      <ul className="product-list">
        {productList.map((data) => (
          <li key={data._id}>
            <div className="fw-bold">{data.title}</div>
            <img src={data.image} alt={data.alt} />
            <div>
              <FontAwesomeIcon
                className="edit-icon"
                icon={faEdit}
                onClick={() => handleEdit(data)}
              />
              <FontAwesomeIcon
                icon={faTrashAlt}
                onClick={() => deleteList(data._id)}
              />
            </div>
          </li>
        ))}
      </ul>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={title.value}
                onChange={handleChange}
                placeholder="title"
              />
              {title.showError ? (
                <p className="errMsg">{title.errormsg}</p>
              ) : null}
            </Form.Group>
            {isImageUploaded ? (
              <div className="dashboard-img-update">
                <img className="popup-img" src={imagedata.image} />
                <FontAwesomeIcon
                  className="close-icon"
                  onClick={() => setIsImageUploaded(false)}
                  icon={faTimes}
                />
              </div>
            ) : (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <ImageUpload imageupload={imageupload} />
              </Form.Group>
            )}

            {errormsg && <p className="errMsg">{errormsg}</p>}
            <div className="cta-btn">
              {mode === "add" ? (
                <Button
                  className="mr-3"
                  variant="primary"
                  disabled={!isImageUploaded}
                  onClick={handlesubmit}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  className="mr-3"
                  variant="primary"
                  disabled={!isImageUploaded}
                  onClick={handleUpdate}
                >
                  Update
                </Button>
              )}
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DashboardComponent;
