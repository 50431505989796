import React, { useState } from "react";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import "./QuestionComponent.scss";
import PropTypes from "prop-types";
import CONSTANTS from "../../constants";
import axios from "axios";
import authStorage from "../../auth";
const TrueorFalseQuestionComponent = (props) => {
  const [question, setQuestion] = useState({
    value: props.editData?.question || "",
    errormsg: "",
    showError: false,
  });
  const [errormsg, setErrorMsg] = useState();
  const [truefalseAnswer, setTruefalseAnswer] = useState(
    props.editData?.truefalseAnswer || ""
  );

  const [optionArry, setOptionArry] = useState(
    props.editData?.optionArry || [
      {
        value: "",
        errormsg: "",
        showError: false,
        checkbox: false,
      },
      {
        value: "",
        errormsg: "",
        showError: false,
        checkbox: false,
      },
    ]
  );
  const handleChange = (e, index) => {
    const newData = [...optionArry];
    newData[index].value = e.target.value;
    newData[index].errormsg = "";
    newData[index].showError = false;
    setOptionArry(newData);
  };

  const toggleItem = (e, index) => {
    const newData = [...optionArry];
    newData[index].checkbox = e.target.checked;
    setOptionArry(newData);
  };

  const handleChangeQuestion = (e) => {
    setQuestion({
      value: e.target.value,
      errormsg: "",
      showError: false,
    });
  };

  const addQuestion = (question, optionArry) => {
    const obj = {
      question: question.value,
      optionArry: optionArry,
      catID: props.catID,
      type: props.type,
      truefalseAnswer: truefalseAnswer,
      questiontype: "trueorfalse",
    };
    axios
      .post(CONSTANTS.devuri +`${props.lang ==='tamil'? 'tamil/':''}questionlist`, obj, {
        headers: { "x-auth-token": authStorage.getToken() },
      })
      .then(() => {
        props.handleClose();
        props.getQuestionList(props.type);
      })
      .catch((err) => {
        setErrorMsg(err.response.data.errors[0].msg);
        console.log(errormsg);
      });
  };

  const updateQuestion = (question, optionArry) => {
    const obj = {
      question: question.value,
      optionArry: optionArry,
      catID: props.catID,
      type: props.type,
      truefalseAnswer: truefalseAnswer,
      questiontype: "trueorfalse",
    };
    axios
      .patch(CONSTANTS.devuri + `${props.lang ==='tamil'? 'tamil/':''}questionlist/${props.editData._id}`, obj, {
        headers: { "x-auth-token": authStorage.getToken() },
      })
      .then(() => {
        props.handleClose();
        props.getQuestionList(props.type);
      })
      .catch((err) => {
        setErrorMsg(err.response.data.errors[0].msg);
        console.log(errormsg);
      });
  };
  return (
    <div className="inner-content-wrapper">
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Question</Form.Label>
          <Form.Control
            type="text"
            name="question"
            value={question.value}
            onChange={handleChangeQuestion}
            placeholder="Question"
          />
          {question.showError ? (
            <p className="errMsg">{question.errormsg}</p>
          ) : null}
        </Form.Group>
        {optionArry.map((data, index) => (
          <Form.Group key={index} className="mb-3">
            <Container>
              <Row xs={2} md={4} lg={6}>
                <Col>
                  <Form.Control
                    type="text"
                    name="Option"
                    value={data.value}
                    placeholder="Option"
                    onChange={(e) => {
                      handleChange(e, index);
                    }}
                  />
                  {data.showError ? (
                    <p className="errMsg">{data.errormsg}</p>
                  ) : null}
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    inline
                    checked={data.checkbox}
                    onChange={(e) => {
                      toggleItem(e, index);
                    }}
                  />
                </Col>
              </Row>
            </Container>
          </Form.Group>
        ))}
        <Form.Group className="mb-3 w-50"> 
          <Form.Control
            type="text"
            name="Correct Answer"
            value={truefalseAnswer}
            placeholder="Correct Answer"
            onChange={(e) => {
              setTruefalseAnswer(e.target.value);
            }}
          />
        </Form.Group>
      </Form>
      <div className="btn-sec">
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
        {props.isEdit ? (
          <Button
            variant="primary"
            onClick={() => updateQuestion(question, optionArry)}
          >
            Update
          </Button>
        ) : (
          <Button
            variant="primary"
            onClick={() => addQuestion(question, optionArry)}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );
};
TrueorFalseQuestionComponent.propTypes = {
  handleClose: PropTypes.func,
  getQuestionList: PropTypes.func,
  catID: PropTypes.string,
  type: PropTypes.string,
  editData: PropTypes.object,
  isEdit: PropTypes.bool,
  lang: PropTypes.string,
};
export default TrueorFalseQuestionComponent;
