import React, { useState, useEffect, useRef } from "react";
import JoditEditor from "jodit-react";
import { Button, Form } from "react-bootstrap";
import "./ActionCardsComponent.scss";
import FileUpload from "../../globalComponents/FileUpload/FileUpload.jsx";
import ImageUploadComponent from "../ImageUploadComponent/ImageUploadComponent.jsx";
import CONSTANTS from "../../constants";
import axios from "axios";
import authStorage from "../../auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const ActionCardsTamilComponent = () => {
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [imagedata, setimagedata] = useState();
  const [errormsg, setErrorMsg] = useState();
  const [catList, setCatList] = useState([]);
  const [catID, setCatID] = useState();
  const [iscatID, setIsCatID] = useState(false);
  const [productDetails, setProductDetails] = useState({});
  const [moreDetails, setMoreDetails] = useState([]);

  const editor = useRef(null);

  const [formData, setFormData] = useState({
    title: {
      value: "",
      errormsg: "",
      showError: false,
    },
    description: {
      value: "",
      errormsg: "",
      showError: false,
    },
    para: {
      value: "",
      errormsg: "",
      showError: false,
    },
  });
  useEffect(() => {
    getcatList();
  }, []);
  const getcatList = () => {
    axios
      .get(CONSTANTS.devuri + "tamil/homelist", {
        headers: { "x-auth-token": authStorage.getToken() },
      })
      .then((response) => {
        setCatList(response.data.imagelist);
      })
      .catch((err) => {
        setErrorMsg(err.response.data.errors[0].msg);
      });
  };

  const restData = () => {
    setimagedata({ image: "", alt: "" });
    setMoreDetails([]); 
    setTimeout(() => {
      setIsImageUploaded(false);
    }, 100);
    setFormData({
      ...formData,
      title: {
        value: "",
        errormsg: "",
        showError: false,
      },
      description: {
        value: "",
        errormsg: "",
        showError: false,
      },
      para: {
        value: "",
        errormsg: "",
        showError: false,
      },
    });
  };
  const updatePara = (data) => {
    setFormData({
      ...formData,
      para: {
        value: data,
        errormsg: "",
        showError: false,
      },
    });
  };
  const updateData = (data) => {
    setimagedata({ image: data.pdflink, alt: "test" });
    setTimeout(() => {
      setIsImageUploaded(true);
    }, 100);
    setFormData({
      ...formData,
      title: {
        value: data.title,
        errormsg: "",
        showError: false,
      },
      description: {
        value: data.description,
        errormsg: "",
        showError: false,
      },
      para: {
        value: data.para,
        errormsg: "",
        showError: false,
      },
    });
    setMoreDetails(data.moreDetails);
  };
  const getDetails = (id) => {
    axios
      .get(CONSTANTS.devuri + `tamil/actioncards/${id}`, {
        headers: { "x-auth-token": authStorage.getToken() },
      })
      .then((response) => {
        setProductDetails(response.data.imagelist);
        if (response.data.imagelist.length > 0) {
          updateData(response.data.imagelist[0]);
        } else {
          restData();
        }
      })
      .catch((err) => {
        setErrorMsg(err.response.data.errors[0].msg);
      });
  };
  const imageupload = (link, imageName) => {
    setimagedata({ image: link.path, alt: imageName });
    setTimeout(() => {
      setIsImageUploaded(true);
    }, 100);
  };

  const handleAddMore = () => {
    const newData = [...moreDetails];
    newData.push({
      isImageUploaded: false,
      imagedata: { image: "", alt: "" },
    });
    setMoreDetails(newData);
  };
  const moreimageupload = (link, imageName, index) => {
    const newData = [...moreDetails];
    newData[index].isImageUploaded = true;
    newData[index].imagedata = { image: link.path, alt: imageName };
    setMoreDetails(newData);
  };
  const handleMoreImageRemove = (index) => {
    const newData = [...moreDetails];
    newData.splice(index, 1);
    setMoreDetails(newData);
  };

  const moreimageuploadClose = (index) => {
    const newData = [...moreDetails];
    newData[index].isImageUploaded = false;
    newData[index].imagedata = { image: "", alt: "" };
    setMoreDetails(newData);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: {
        value: e.target.value,
        errormsg: "",
        showError: false,
      },
    });
  };

  const handleValidation = () => {
    let formIsValid = true;
    let obj = {};
    if (!formData.title.value) {
      formIsValid = false;
      obj.title = {
        value: formData.title.value,
        errormsg: "This is required field",
        showError: true,
      };
    }
    if (!formData.description.value) {
      formIsValid = false;
      obj.description = {
        value: formData.description.value,
        errormsg: "This is required field",
        showError: true,
      };
    }

    const newData = { ...formData, ...obj };
    setFormData(newData);
    return formIsValid;
  };
  const handlesubmit = () => {
    if (handleValidation()) {
      let obj = {
        title: formData.title.value,
        description: formData.description.value,
        para: formData.para.value,
        pdflink: imagedata.image,
        catID: catID,
        moreDetails: moreDetails,
      };
      axios
        .post(CONSTANTS.devuri + `tamil/actioncards/`, obj, {
          headers: { "x-auth-token": authStorage.getToken() },
        })
        .then(() => {
          setCatID("");
          setIsCatID(false);
          restData();
        })
        .catch((err) => {
          setErrorMsg(err.response.data.errors[0].msg);
        });
    }
  };
  const categoryChange = (e) => {
    setCatID(e.target.value);
    getDetails(e.target.value);
    setIsCatID(true);
  };
  const handleUpdate = () => {
    if (handleValidation()) {
      let obj = {
        title: formData.title.value,
        description: formData.description.value,
        para: formData.para.value,
        pdflink: imagedata.image,
        catID: catID,
        moreDetails: moreDetails,

      };
      axios
        .patch(CONSTANTS.devuri + `tamil/actioncards/${catID}`, obj, {
          headers: { "x-auth-token": authStorage.getToken() },
        })
        .then(() => {
          setCatID();
          setIsCatID(false);
          restData();
        })
        .catch((err) => {
          setErrorMsg(err.response.data.errors[0].msg);
        });
    }
  };

  return (
    <div className="inner-content-wrapper">
      <div>
        <Form.Select
          aria-label="Default select example"
          onChange={categoryChange}
        >
          <option>Select Category</option>
          {catList.map((data) => (
            <option key={data._id} value={data._id}>
              {data.title}
            </option>
          ))}
        </Form.Select>
      </div>
      {iscatID ? (
        <div className="learning-material-wrapper">
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={formData.title.value}
              onChange={handleChange}
              placeholder="title"
            />
            {formData.title.showError ? (
              <p className="errMsg">{formData.title.errormsg}</p>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              name="description"
              value={formData.description.value}
              onChange={handleChange}
              placeholder="Description"
            />
            {formData.description.showError ? (
              <p className="errMsg">{formData.description.errormsg}</p>
            ) : null}
          </Form.Group>
          <ImageUploadComponent />
          <div
            style={{
              padding: "2px",
            }}
          >
            <JoditEditor
              ref={editor}
              value={formData.para.value}
              config={{
                readonly: false,
              }}
              tabIndex={1} // tabIndex of textarea
              onBlur={(newContent) => updatePara(newContent)} // preferred to use only this option to update the content for performance reasons
              onChange={(newContent) => {
                console.log(newContent);
              }}
            />
          </div>
          <div className="popup-img-sec">
            {isImageUploaded ? (
              <div className="file-upload-sec">
                <img src="assets/file-icon.png" />
                <p>{imagedata.image}</p>
                <FontAwesomeIcon
                  className="close-icon"
                  onClick={() => setIsImageUploaded(false)}
                  icon={faTimes}
                />
              </div>
            ) : (
              <Form.Group className="mb-3">
                <FileUpload imageupload={imageupload} />
              </Form.Group>
            )}
          </div>

          {moreDetails.map((data, index) => (
            <div className="popup-img-sec" key={index}>
              {data.isImageUploaded ? (
                <div className="file-upload-sec">
                  <img src="assets/file-icon.png" />
                  <p>{data.imagedata.image}</p>
                  <FontAwesomeIcon
                    className="close-icon"
                    onClick={() => moreimageuploadClose(index)}
                    icon={faTimes}
                  />
                </div>
              ) : (
                <>
                  <Form.Group className="mb-3">
                    <FileUpload
                      imageupload={(link, imageName) =>
                        moreimageupload(link, imageName, index)
                      }
                    />
                    <Button
                      variant="primary"
                      onClick={() => handleMoreImageRemove(index)}
                    >
                      Remove
                    </Button>
                  </Form.Group>
                </>
              )}
            </div>
          ))}
          <Button
            className="mr-3 mt-5"
            variant="primary"
            onClick={handleAddMore}
          >
            Add More Files
          </Button>
          {errormsg && <p className="errMsg">{errormsg}</p>}

          <div className="cta-btn">
            {productDetails.length > 0 ? (
              <Button
                className="mr-3"
                variant="primary"
                disabled={!isImageUploaded}
                onClick={handleUpdate}
              >
                Update
              </Button>
            ) : (
              <Button
                className="mr-3"
                variant="primary"
                disabled={!isImageUploaded}
                onClick={handlesubmit}
              >
                Submit
              </Button>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ActionCardsTamilComponent;
