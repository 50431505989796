import React, { useState } from "react";
import "./Login.scss";
import CONSTANTS from "../../constants";
import axios from "axios";
import authStorage from "../../auth";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [errormsg, setErrorMsg] = useState();
  const [loginForm, setLoginForm] = useState({
    username: { value: "", errormsg: "", showError: false },
    password: { value: "", errormsg: "", showError: false },
  });

  const handleChange = (e) => {
    setLoginForm({
      ...loginForm,
      [e.target.name]: {
        value: e.target.value,
        errormsg: "",
        showError: false,
      },
    });
  };
  const handleValidation = () => {
    let formIsValid = true;
    let obj = {};

    if (!loginForm.username.value) {
      formIsValid = false;
      obj.username = {
        value: loginForm.username.value,
        errormsg: "This is required field",
        showError: true,
      };
    }

    if (!loginForm.password.value) {
      formIsValid = false;
      obj.password = {
        value: loginForm.password.value,
        errormsg: "This is required field",
        showError: true,
      };
    }
    const newData = { ...loginForm, ...obj };
    setLoginForm(newData);
    return formIsValid;
  };
  const handleClick = () => {
    if (handleValidation()) {
      const obj = {
        email: loginForm.username.value,
        password: loginForm.password.value,
      };

      axios
        .post(CONSTANTS.devuri + "auth", obj)
        .then((response) => {
          authStorage.storeToken(response.data.token);
          authStorage.storeUser(response.data.role);
          authStorage.setIsAuth();
          navigate("/dashboard");
        })
        .catch((err) => {
          setErrorMsg(err.response.data.errors[0].msg);
        });
    }
  };

  return (
    <div className="login-warpper">
      <div className="login-sec">
        <img src="assets/auth.png" className="mb-3" alt="auth" />

        <div className="form-floating mb-3 custominput">
          <input
            id={"username"}
            name={"username"}
            type="email"
            className="form-control"
            placeholder="username"
            value={loginForm.username.value}
            onChange={handleChange}
          />
          <label>Email address</label>
          {loginForm.username.showError && (
            <p className="errMsg">{loginForm.username.errormsg}</p>
          )}
        </div>
        <div className="form-floating mb-3 custominput">
          <input
            type="password"
            className="form-control"
            id={"Password"}
            name={"password"}
            placeholder="Password"
            value={loginForm.password.value}
            onChange={handleChange}
          />
          <label>Password</label>
          {loginForm.password.showError && (
            <p className="errMsg">{loginForm.password.errormsg}</p>
          )}
        </div>

        <p className="errMsg">{errormsg}</p>
        <button
          className="btn btn-primary login-btn mb-3"
          onClick={handleClick}
        >
          Login
        </button>
        <p className="login-footer">
          By signing up i accept the <span>terms</span> of <span>use</span> and
          the data
          <span>
            <a href="assets/Privacy-policy.pdf">privacy policy</a>
          </span>
        </p>
      </div>
    </div>
  );
};
export default Login;
