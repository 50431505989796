/* eslint-disable no-useless-escape */

const CONSTANTS = {
  formValidation: {
    namePattern: /\w*?(\s\w)*$/,
    onlyAlphabet: /^[a-zA-Z ]*$/,
    onlyNumbers: /^[0-9]*$/,
    onlyAlphabetNumbers: /^[0-9a-zA-Z.,:/ ]*$/,
    isEmail: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)+(\.\w{2,3})+$/,
    passwordValidation:
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
    onlyNumbersWithDot: /^[\.0-9]*$/,
  },
  errMessages: {
    firstNameReq: "First Name Required",
    lastName: "Last Name Required",
    emailReq: "Email Id Required",
    phoneReq: "Phone Number Required",
  },
  devuri: `/api/api/`,
};
export default CONSTANTS;
