import React, { useState } from "react";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import "./QuestionComponent.scss";
import PropTypes from "prop-types";
import CONSTANTS from "../../constants";
import axios from "axios";
import authStorage from "../../auth";
const QuestionSequenceComponent = (props) => {
  console.log(props.editData);
  const [question, setQuestion] = useState({
    value: props.editData?.question || "",
    errormsg: "",
    showError: false,
  });
  const [errormsg, setErrorMsg] = useState();

  const [optionSqu, setoptionSqu] = useState(
    props.editData?.optionSqu || [
      {
        value: "",
        errormsg: "",
        showError: false,
      },
    ]
  );
  const [optionSquAnswer, setoptionSquAnswer] = useState(
    props.editData?.optionSquAnswer || [
      {
        value: "",
        errormsg: "",
        showError: false,
      },
    ]
  );
  const handleChange = (e, index) => {
    const newData = [...optionSqu];
    newData[index].value = e.target.value;
    newData[index].errormsg = "";
    newData[index].showError = false;
    setoptionSqu(newData);
  };
  const handleSeqChange = (e, index) => {
    const newData = [...optionSquAnswer];
    newData[index].value = e.target.value;
    newData[index].errormsg = "";
    newData[index].showError = false;
    setoptionSquAnswer(newData);
  };

  const handleChangeQuestion = (e) => {
    setQuestion({
      value: e.target.value,
      errormsg: "",
      showError: false,
    });
  };
  const handleAddOption = () => {
    const newData = [...optionSqu];
    newData.push({
      value: "",
      errormsg: "",
      showError: false,
    });
    const newDataCrt = [...optionSquAnswer];
    newDataCrt.push({
      value: "",
      errormsg: "",
      showError: false,
    });
    setoptionSqu(newData);
    setoptionSquAnswer(newDataCrt);
  };
  const handleRemoveOption = (index) => {
    const newData = [...optionSqu];
    newData.splice(index, 1);
    const newDataCrt = [...optionSquAnswer];
    newDataCrt.splice(index, 1);
    setoptionSqu(newData);
    setoptionSquAnswer(newDataCrt);
  };

  const addQuestion = (question, optionSqu) => {
    const obj = {
      question: question.value,
      optionSqu: optionSqu,
      optionSquAnswer: optionSquAnswer,
      catID: props.catID,
      type: props.type,
      questiontype: "sequence",
    };
    axios
      .post(CONSTANTS.devuri + `${props.lang ==='tamil'? 'tamil/':''}questionlist`, obj, {
        headers: { "x-auth-token": authStorage.getToken() },
      })
      .then(() => {
        props.handleClose();
        props.getQuestionList(props.type);
      })
      .catch((err) => {
        setErrorMsg(err.response.data.errors[0].msg);
        console.log(errormsg);
      });
  };

  const updateQuestion = (question, optionSqu) => {
    const obj = {
      question: question.value,
      optionSqu: optionSqu,
      optionSquAnswer: optionSquAnswer,
      catID: props.catID,
      type: props.type,
      questiontype: "sequence",
    };
    axios
      .patch(CONSTANTS.devuri + `${props.lang ==='tamil'? 'tamil/':''}questionlist/${props.editData._id}`, obj, {
        headers: { "x-auth-token": authStorage.getToken() },
      })
      .then(() => {
        props.handleClose();
        props.getQuestionList(props.type);
      })
      .catch((err) => {
        setErrorMsg(err.response.data.errors[0].msg);
        console.log(errormsg);
      });
  };
  return (
    <div className="inner-content-wrapper">
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Question</Form.Label>
          <Form.Control
            type="text"
            name="question"
            value={question.value}
            onChange={handleChangeQuestion}
            placeholder="Question"
          />
          {question.showError ? (
            <p className="errMsg">{question.errormsg}</p>
          ) : null}
        </Form.Group>

        <Form.Group className="mb-3">
          <Container>
            <Row>
              <Col md={8}>
                <p>Options </p>
                {optionSqu.map((data, index) => (
                  <Row key={index}>
                    <Col>
                      <Form.Control
                        type="text"
                        name="Option"
                        value={data.value}
                        placeholder="Option"
                        onChange={(e) => {
                          handleChange(e, index);
                        }}
                      />
                      {data.showError ? (
                        <p className="errMsg">{data.errormsg}</p>
                      ) : null}
                    </Col>
                    <Col>
                      <Button
                        variant="primary"
                        className="btn-remove-sec"
                        onClick={() => handleRemoveOption(index)}
                      >
                        Remove
                      </Button>
                    </Col>
                  </Row>
                ))}
              </Col>
              <Col md={4}>
                <p>Correct Sequence</p>
                {optionSquAnswer.map((data, index) => (
                  <Row key={index}>
                    <Col>
                      <Form.Control
                        type="text"
                        name="Option"
                        value={data.value}
                        placeholder="Option"
                        onChange={(e) => {
                          handleSeqChange(e, index);
                        }}
                      />
                      {data.showError ? (
                        <p className="errMsg">{data.errormsg}</p>
                      ) : null}
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </Container>
        </Form.Group>

        <Button variant="primary" onClick={handleAddOption}>
          Add More
        </Button>
      </Form>
      <div className="btn-sec">
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
        {props.isEdit ? (
          <Button
            variant="primary"
            onClick={() => updateQuestion(question, optionSqu)}
          >
            Update
          </Button>
        ) : (
          <Button
            variant="primary"
            onClick={() => addQuestion(question, optionSqu)}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );
};
QuestionSequenceComponent.propTypes = {
  handleClose: PropTypes.func,
  getQuestionList: PropTypes.func,
  catID: PropTypes.string,
  type: PropTypes.string,
  editData: PropTypes.object,
  isEdit: PropTypes.bool,
  lang: PropTypes.string,

};
export default QuestionSequenceComponent;
